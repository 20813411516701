<script>
import Layout from '@/views/layouts/main'
import { onMounted, ref, watch } from 'vue'
import { getNotificationsTemplate, deleteNotificationTemplate } from './api'
import AppPagination from '@/components/app-pagination'
import Notify from '@/notify'
import debounce from '@/utils/debounce'

export default {
  setup () {
    const notificationTemplates = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      id: null,
      name: null,
      title: null,
      content: null,
      short_description: null,
      type: null,
      created_at: {
        from: null,
        to: null
      },
      updated_at: {
        from: null,
        to: null
      }
    })

    onMounted(() => {
      fetchNotificationsTemplate()
    })

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const fetchNotificationsTemplate = debounce(() => {
      const params = {
        page: page.value,
        contest_id: filter.value?.contest_id?.value,
        user_id: filter.value?.user_id?.value,
        status: filter.value.status,
        created_at: {
          gte: dateToISO(filter.value.created_at?.from),
          lte: dateToISO(filter.value.created_at?.to)
        },
        confirmed_at: {
          gte: dateToISO(filter.value.confirmed_at?.from),
          lte: dateToISO(filter.value.confirmed_at?.to)
        },
        order: {
          created_at: 'desc'
        }
      }

      getNotificationsTemplate(params).then((data) => {
        notificationTemplates.value = data.data
        pages.value = data.last_page
        total.value = data.total
      }).catch(() => {
        Notify.create({
          title: 'Falha ao carregar a notificação',
          timer: 1500
        })
      })
    }, 1000)

    watch(filter, () => {
      page.value = 1
      fetchNotificationsTemplate()
    }, { deep: true })

    const deletedNotifications = (id) => {
      deleteNotificationTemplate(id).then(() => {
        Notify.create({
          type: 'positive',
          title: 'Notificação excluída com sucesso.'
        })
        fetchNotificationsTemplate()
      }).catch(() => {
        Notify.create({
          type: 'negative',
          title: 'Falha ao excluir notificação.'
        })
      })
    }

    return {
      page,
      pages,
      total,
      notificationTemplates,
      fetchNotificationsTemplate,
      filter,
      deletedNotifications
    }
  },
  components: {
    AppPagination,
    Layout
  }
}
</script>

<template>
  <Layout :pagetitle="'Template de Notificação'">
    <div class="col-md-12">
      <div
        class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3"
      >
        <div>
          <ul class="nav nav-pills">
            <div>
              <router-link
                :to="{ name: 'notifications_templates_add' }"
                class="btn btn-primary"
              >
                <i class="bx bx-plus me-1" /> Novo Template de Notificação
              </router-link>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-striped mb-4"
          >
            <thead>
              <tr>
                <th scope="col">
                  Nome
                </th>
                <th scope="col">
                  Título
                </th>
                <th scope="col">
                  Conteúdo
                </th>
                <th scope="col">
                  Descrição Curta
                </th>
                <th scope="col">
                  Tipo
                </th>
                <th scope="col">
                  Criado em
                </th>
                <th scope="col">
                  Atualizado em
                </th>
                <th scope="col">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="template in notificationTemplates"
                :key="template.id"
              >
                <td>{{ template.name }}</td>
                <td>{{ template.title }}</td>
                <td>{{ template.content }}</td>
                <td>{{ template.short_description }}</td>
                <td>{{ template.type }}</td>
                <td>{{ $d(template.created_at, 'info') }}</td>
                <td>{{ $d(template.updated_at, 'info') }}</td>
                <td class="d-flex justify-content-between" style="width: 100%;">
                  <router-link
                    :to="{ name: 'notification-templates_edit', params: { id: template.id } }"
                    class="btn btn-outline-info w-100 me-1"
                  >
                    <i class="bx bx-cog" />
                    Editar
                  </router-link>
                  <a
                    href="#"
                    @click.prevent="deletedNotifications(template.id)"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    class="btn btn-outline-danger w-100 ms-1"
                  >
                    <i class="bx bx-trash" />
                    Excluir
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <app-pagination
            v-model="page"
            class="pagination"
            :pages="pages"
            :range-size="1"
            @update:modelValue="fetchNotificationsTemplate"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>
