import { axios } from '@/axios'

export const getNotificationsTemplate = (params = {}) => {
  return axios.get('/notifications-templates', { params }).then(({ data }) => data)
}

export const createNotificationTemplate = (notificationData) => {
  return axios.post('/notifications-templates', notificationData).then(({ data }) => data)
}
export const deleteNotificationTemplate = (id) => {
  return axios.delete(`/notifications-templates/${id}`).then(({ data }) => data)
}
export const updateNotificationTemplate = (id, params = {}) => {
  return axios.put(`/notifications-templates/${id}`, params).then(({ data }) => data)
}
export const showNotificationTemplate = (id) => {
  return axios.get(`/notifications-templates/${id}`).then(({ data }) => data)
}
